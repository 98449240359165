import axios from 'axios';


const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const profileSlice = createSlice({
    name: 'profile',
    initialState:{
        data:[],
        status:STATUSES.IDLE
    },
    reducers: {
        // setProducts(state, action) {
        //     state.data = action.payload;
        // },
        // setStatus(state, action) {
        //     state.status = action.payload;
        // },
        logout(state,action){
            state.data=[]
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                console.log(action.payload);
                state.data = action.payload;
                state.status = STATUSES.IDLE;
                               
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(addProfile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(addProfile.fulfilled, (state, action) => {                
                state.status = STATUSES.IDLE;                
                state.data=action.payload;                
            }) 
            .addCase(addProfile.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(patchProfile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(patchProfile.fulfilled, (state, action) => {                
                state.status = STATUSES.IDLE;                
                state.data=action.payload;                
            }) 
            .addCase(patchProfile.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(updateProfile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                console.log(action.payload);
                state.data =action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    },
});

export const { setProfile, setStatus } = profileSlice.actions;
export default profileSlice.reducer;

// Thunks
export const getProfile = createAsyncThunk('profile/get', async (id) => {
    console.log('thunk id',id);
    const res = await axios.get(`https://backend.bankajewellers.com/api/profile/${id}`);
   //console.log(res)
//const data =  res;
    //console.log(data.productCategory);
    return res.data.profiles;
});

export const addProfile = createAsyncThunk('profile/post', async (formData) => {
    const res = await axios.post('https://backend.bankajewellers.com/api/profile',formData);
   //console.log(res)
   // const data =  res;
    //console.log(data);
    return res.data;
});
export const patchProfile = createAsyncThunk('profile/patch', async (formData) => {
    const res = await axios.patch('https://backend.bankajewellers.com/api/profile',formData);
   //console.log(res)
   // const data =  res;
    //console.log(data);
    return res.data;
});




export const updateProfile = createAsyncThunk('profile/update', async (formData) => {
    
    const res = await axios.post('https://backend.bankajewellers.com/api/profile/update',formData,
    {
        headers: {
          contentType: 'application/json',
        },
      }
   
    );
   //console.log(res)
    const data =  res.data;
    //console.log(data.productCategory);
    return data;
});


// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
