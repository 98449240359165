import axios from 'axios';
import {socketConnect} from "../components/socketConnect";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  data: [],
  status: STATUSES.IDLE,
};

const testimonialSlice = createSlice({
  name: 'testiMonials',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveTestimonial.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(saveTestimonial.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data.push(action.payload);
      })
      .addCase(saveTestimonial.rejected, (state) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(getTestimonials.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getTestimonials.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = action.payload;
      })
      .addCase(getTestimonials.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setTestimonials } = testimonialSlice.actions;
export default testimonialSlice.reducer;

const socket = socketConnect();



export const saveTestimonial = createAsyncThunk(
    'testimonials/saveTestimonial',
    async (testimonial) => {
      try {
        const response = await new Promise((resolve, reject) => {
          socket.emit('saveTestimonial', testimonial);
          socket.on('testimonialSuccess', (data) => {
            resolve(data);
          });
          socket.on('testimonialError', (err) => {
            reject(err);
          });
        });
        return response;
      } catch (err) {
        throw err;
      }
    }
  );
  

export const getTestimonials = createAsyncThunk(
  'testimonials/getTestimonials',
  async () => {   
    try {
      const response = await new Promise((resolve, reject) => {
        socket.emit('getTestimonials', null);
        socket.on('testimonialsList', (data) => {
          resolve(data);
        });
        socket.on('testimonialsError', (err) => {
          reject(err);
        });
      });
      return response;
    } catch (err) {
      throw err;
    }
  }
);
