// src/features/auctionEvent/AuctionEventSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { socketConnect } from "../components/socketConnect";

const socket = socketConnect();

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data: [],
  bids: [],
  status: STATUSES.IDLE,
};

const auctionEventSlice = createSlice({
  name: "auctionEvent",
  initialState,
  reducers: {
    // Custom reducers if needed
    updateHighestBid: (state, action) => {
      const { eventId, highestBid } = action.payload;
      console.log("highestbid", highestBid);
      const auctionEvent = state.data.find((event) => event._id === eventId);
      console.log("auctionEvent", auctionEvent);
      if (auctionEvent) {
        auctionEvent.highestBid = highestBid;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createAuctionEvent.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(createAuctionEvent.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data.push(action.payload);
      })
      .addCase(createAuctionEvent.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchAuctionEvent.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAuctionEvent.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchAuctionEvent.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchAuctionEvents.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAuctionEvents.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchAuctionEvents.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(updateAuctionEvent.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateAuctionEvent.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        const updatedEvent = action.payload;
        const eventIndex = state.data.findIndex(
          (event) => event._id === updatedEvent._id
        );
        if (eventIndex !== -1) {
          state.data[eventIndex] = updatedEvent;
        }
      })
      .addCase(updateAuctionEvent.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(deleteAuction.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteAuction.fulfilled, (state, action) => {
        const deletedId = action.payload;
        state.data = state.data.filter((auction) => auction._id !== deletedId);
        state.status = STATUSES.IDLE;
      })
      .addCase(deleteAuction.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(placeBid.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(placeBid.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        const { eventId, bidAmount } = action.payload;
        const auctionEvent = state.data.find((ae) => ae._id === eventId);
        auctionEvent.highestBid = bidAmount;
        auctionEvent.highestBidder = action.meta.arg.bidderId;
      })
      .addCase(placeBid.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchBidsByAuctionEvent.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchBidsByAuctionEvent.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.bids = action.payload;
      })
      .addCase(fetchBidsByAuctionEvent.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchBids.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchBids.fulfilled, (state, action) => {
        state.bids = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchBids.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

// Thunks
export const createAuctionEvent = createAsyncThunk(
  "auctionEvent/create",
  async (formData) => {
    console.log("formData createAuctionEvent", formData);
    const response = await new Promise((resolve, reject) => {
      socket.emit("createAuctionEvent", formData);
      socket.on("auctionEventCreated", (data) => {
        resolve(data);
      });
      socket.on("auctionEventError", (err) => {
        reject(err);
      });
    });
    return response;
  }
);
export const fetchAuctionEvent = createAsyncThunk(
  "auctionEvent/fetchById",
  async (id) => {
    console.log("id fetchAuctionEvent", id);
    const response = await new Promise((resolve, reject) => {
      socket.emit("fetchAuctionEvent", id);
      socket.on("auctionEventFetched", (data) => {
        resolve(data);
      });
      socket.on("auctionEventError", (err) => {
        reject(err);
      });
    });
    return response;
  }
);
export const deleteAuction = createAsyncThunk("DeleteEvent/Id", async (id) => {
  console.log("id deleteAuctionEvent", id);
  const response = await new Promise((resolve, reject) => {
    socket.emit("deleteAuction", id);
    socket.on("AuctionDeleteSuccess", (deletedId) => {
      resolve(deletedId);
    });
    socket.on("auctionDeleteError", (err) => {
      reject(err);
    });
  });
  return response;
});

export const fetchAuctionEvents = createAsyncThunk(
  "auctionEvent/fetchAll",
  async () => {
    const response = await new Promise((resolve, reject) => {
      socket.emit("fetchAllAuctionEvents");
      socket.on("auctionEventsFetched", (data) => {
        resolve(data);
      });
      socket.on("auctionEventsError", (err) => {
        reject(err);
      });
    });
    return response;
  }
);

export const updateAuctionEvent = createAsyncThunk(
  "auctionEvent/update",
  async (updatedEvent) => {
    console.log("updatedEvent", updatedEvent);
    const response = await new Promise((resolve, reject) => {
      socket.emit("updateAuctionEvent", updatedEvent);
      socket.on("auctionEventUpdated", (data) => {
        resolve(data);
      });
      socket.on("auctionEventError", (err) => {
        reject(err);
      });
    });
    return response;
  }
);

export const placeBid = createAsyncThunk(
  "auctionEvent/placeBid",
  async ({ eventId, bidderId, bidAmount }) => {
    const response = await new Promise((resolve, reject) => {
      socket.emit("placeBid", { eventId, bidderId, bidAmount });
      socket.on("bidSuccess", (data) => {
        resolve(data);
      });
      socket.on("bidError", (err) => {
        reject(err);
      });
    });
    return { eventId, bidAmount };
  }
);
export const fetchBids = createAsyncThunk(
  "auctionEvent/fetchBids",
  async () => {
    console.log("fetchBids");
    const response = await new Promise((resolve, reject) => {
      socket.emit("getBids");
      socket.on("bidsFetched", (data) => {
        resolve(data);
      });
      socket.on("bidsError", (err) => {
        reject(err);
      });
    });

    return response;
  }
);
export const fetchBidsByAuctionEvent = createAsyncThunk(
  "auctionEvent/fetchBidsByAuctionEvent",
  async (eventId) => {
    const response = await new Promise((resolve, reject) => {
      socket.emit("getBidsByEventId", eventId);
      socket.on("bidsByAuctionEventFetched", (data) => {
        resolve(data);
      });
      socket.on("bidsByAuctionEventError", (err) => {
        reject(err);
      });
    });

    return response;
  }
);

export const { updateHighestBid } = auctionEventSlice.actions;

export default auctionEventSlice.reducer;
