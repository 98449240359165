import axios from 'axios';
import {socketConnect} from "../components/socketConnect";

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');


export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});
const initialState={
    _id:null,
    name:'',
    email:'',
    isAdmin:false,
    token:null
};

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },
    reducers: {
        // setProducts(state, action) {
        //     state.data = action.payload;
        // },
        // setStatus(state, action) {
        //     state.status = action.payload;
        // },
        logout(state,action){
            state.data=initialState
        }
    },
    extraReducers: (builder) => {
        builder
        /*
            .addCase(addOneUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(addOneUser.fulfilled, (state, action) => {
                console.log(action.payload); 
                state.status = STATUSES.IDLE;               
                state.data = action.payload;
                                               
            })
            .addCase(addOneUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            */
            .addCase(updateUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                console.log(action.payload);
                state.status = STATUSES.IDLE;
                state.data = action.payload;             
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(registerSeller.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(registerSeller.fulfilled, (state, action) => {
                state.data =action.payload;
                state.status = STATUSES.IDLE;                
            })
            .addCase(registerSeller.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(registerBuyer.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(registerBuyer.fulfilled, (state, action) => {
                state.data =action.payload;
                state.status = STATUSES.IDLE;                
            })
            .addCase(registerBuyer.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(registerAffiliate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(registerAffiliate.fulfilled, (state, action) => {
                state.data =action.payload;
                state.status = STATUSES.IDLE;                
            })
            .addCase(registerAffiliate.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(policyUpload.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(policyUpload.fulfilled, (state, action) => {
                state.data =action.payload;
                state.status = STATUSES.IDLE;                
            })
            .addCase(policyUpload.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(addPolicy.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(addPolicy.fulfilled, (state, action) => {
                state.data = { ...state.data, ...action.payload };
                state.status = STATUSES.IDLE;                
            })
            .addCase(addPolicy.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(loginUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.data =action.payload;
                state.status = STATUSES.IDLE;                
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(getUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getUser.fulfilled, (state, action) => {                
                state.data = { ...state.data, ...action.payload };
                state.status = STATUSES.IDLE;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(deleteUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.data = null; // Clear the user data after deletion
                state.status = STATUSES.IDLE;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
            .addCase(verifyOtp.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.data =action.payload;
                state.status = STATUSES.IDLE;                
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    },
});

export const { setUser, setStatus,logout } = userSlice.actions;
export default userSlice.reducer;


const socket = socketConnect();

// Thunks
export const getUser = createAsyncThunk('user/get', 
    async (userId) => {
        console.log('fetch users before useSocket emit')
        return new Promise((resolve) => {
          socket.emit("getUser", userId);
          
          socket.on('user', (data) => {            
              resolve(data)
            });
        
    });

});

export const deleteUser = createAsyncThunk('user/delete', 
    async (userId) => {
        return new Promise((resolve)=>{
            socket.emit("deleteUser",userId);

            socket.on('user',(data)=>{
                resolve(data)
            })
        })
    }
);


export const updateUser = createAsyncThunk(
    'user/update',
    async (formData) => {
        console.log("update user thunk",formData);
      const response = await new Promise((resolve, reject) => {
        socket.emit('updateUser', formData);
        socket.on('userUpdated', (data) => {
          resolve(data);
        });
        socket.on('userUpdateError', (err) => {
          reject(err);
        });
      });
      return response;
    }
  );


export const loginUser = createAsyncThunk(
    'user/login',
    async ({ email, password,otpVerified }) => {
      const response = await new Promise((resolve, reject) => {
        socket.emit('loginUser', { email, password,otpVerified })
        socket.on('loginSuccess', (data) => {
            console.log("loginSuccess",data);
          resolve(data);        
        });
        

        socket.on('loginFailure', (err) => {
          reject(err);
        });
      });
      return response;
    }
  );
  export const verifyOtp = createAsyncThunk('user/verifyOtp', 
  async ({email,otp}) => {   
      const response = await new Promise((resolve, reject) => {
        socket.emit('verifyOtp', {email,otp});
        socket.on('otpVerified', (data) => {
            resolve(data);
        });
        socket.on('otpVerificationFailed', (err) => {
            reject(err);
        });
    });
    return response;
  });
  export const policyUpload = createAsyncThunk(
    'policy/upload',
    async (formData) => {
        console.log('formData in userslice policy upload',formData);
        const response = await new Promise((resolve, reject) => {
        socket.emit('policyUpload', formData);
        socket.on('policyUploadSuccess', (data) => {
            resolve(data);
            });
            socket.on('policyUploadFailure', (err) => {
            reject(err);
            });
        }); return response;
    }
    );
  export const registerSeller = createAsyncThunk(
    'register/seller', 
    async (formData) => {
    console.log('formData in userslice',formData);
    const response = await new Promise((resolve, reject) => {
    socket.emit('registerSeller', formData);
    socket.on('registrationSuccess', (data) => {
        resolve(data);
        });
        socket.on('registerFailure', (err) => {
        reject(err);
        });
  }); return response;
}
);
export const addPolicy = createAsyncThunk(
    'add/policy', 
    async (formData) => {
    console.log('formData in userslice',formData);
    const response = await new Promise((resolve, reject) => {
    socket.emit('addPolicy', formData);
    socket.on('addPolicySuccess', (data) => {
        resolve(data);
        });
        socket.on('addPolicyFailure', (err) => {
        reject(err);
        });
  }); return response;
}
);
export const registerBuyer = createAsyncThunk(
    'register/buyer', 
    async (formData) => {
    console.log('formData in userslice',formData);
    const response = await new Promise((resolve, reject) => {
    socket.emit('registerBuyer', formData);
    socket.on('registrationSuccess', (data) => {
        resolve(data);
        });
        socket.on('registerFailure', (err) => {
        reject(err);
        });
  }); return response;
}
);
export const registerAffiliate = createAsyncThunk(
    'register/affiliate', 
    async (formData) => {
    console.log('formData in userslice',formData);
    const response = await new Promise((resolve, reject) => {
    socket.emit('registerAffiliate', formData);
    socket.on('registrationAffiliateSuccess', (data) => {
        resolve(data);
        });
        socket.on('registerAffiliateFailure', (err) => {
        reject(err);
        });
  }); return response;
}
);



// export function fetchProducts() {
//     return async function fetchProductThunk(dispatch, getState) {
//         dispatch(setStatus(STATUSES.LOADING));
//         try {
//             const res = await fetch('https://fakestoreapi.com/products');
//             const data = await res.json();
//             dispatch(setProducts(data));
//             dispatch(setStatus(STATUSES.IDLE));
//         } catch (err) {
//             console.log(err);
//             dispatch(setStatus(STATUSES.ERROR));
//         }
//     };
// }
