const io = require("socket.io-client");

let socket = null;

module.exports.socketConnect = () => {
  
  if (!socket) {
    //socket = io('wss://gls-exchange.com:5100',
    socket = io(process.env.REACT_APP_SOCKET_URL,
  // socket = io('138.201.53.244:5501',
    {            
       timeout: 100000, // 100 seconds
       transports: ['websocket']
     });
   
    socket.on("connect", () => {
      console.log("Connected to server");
    });
    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
    socket.on('products:fetchSuccess',(data)=>{
      console.log('socketconnect products:fetchsuccess');
      console.log(data);
    });    
  } else {
    console.log('Using existing socket instance');
  }
  return socket;
};
