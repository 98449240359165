import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { socketConnect } from "../components/socketConnect";
import axios from "axios";

const socket = socketConnect();

const blogSlice = createSlice({
  name: "blog",
  initialState: { 
  loading: false,
  error: null,
  blogs: [], 
  blog:{}
},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state) => {
        console.log("getBlogs.pending");
        state.loading = true;
      })
      
      .addCase(getBlogs.fulfilled, (state, action) => {
        console.log("getBlogs.fulfilled", action.payload);
        state.blogs = action.payload;
        state.loading = false;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        console.log("getBlogs.rejected", action.error);
        state.loading = false;
      })
      .addCase(getBlogBySlug.pending, (state) => {
        console.log("getBlogBySlug.pending");
        state.loading = true;
      })
      
      .addCase(getBlogBySlug.fulfilled, (state, action) => {
        console.log("getBlogBySlug.fulfilled", action.payload);
        state.blog = action.payload;
      // state.blogs = [...state.blogs, action.payload];
      //state.blogs.push(action.payload);
        state.loading = false;
      })
      .addCase(getBlogBySlug.rejected, (state, action) => {
        console.log("getBlogBySlug.rejected", action.error);
        state.loading = false;
      })
      .addCase(deleteBlog.pending, (state) => {
        console.log("getBlogBySlug.pending");
        state.loading = true;
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        console.log("getBlogBySlug.fulfilled", action.payload);
        state.blogs = action.payload;
        state.loading = false;
      })
      .addCase(deleteBlog.rejected, (state, action) => {
        console.log("getBlogBySlug.rejected", action.error);
        state.loading = false;
      })
      .addCase(getBlogBySlugHTTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogBySlugHTTP.fulfilled, (state, action) => {
        state.blog = action.payload;
        state.loading = false;
      })
      .addCase(getBlogBySlugHTTP.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const getBlogs = createAsyncThunk("blog/getBlogs", async () => {
  console.log('getBlogs blog slice');
  return new Promise((resolve) => {
    socket.emit("getBlogs",null);
    socket.on("blogsFound", (data) => {
      resolve(data);
    });
  });
});

export const deleteBlog = createAsyncThunk("delet/blog", async (id) => {
  console.log('getBlogs blog slice');
  return new Promise((resolve) => {
    socket.emit("deleteBlog",id);
    socket.on("blogDeleteSuccess", (data) => {
      resolve(data);
    });
  });
});

export const getBlogById = createAsyncThunk("blog/getBlogById", async () => {
  const response = await new Promise((resolve) => {
    socket.emit("getBlogById", (blog) => {
      resolve(blog);
    });
  });
  return response;
});
/*
export const getBlogBySlug = createAsyncThunk(
  "blog/getBlogBySlug",
  async (slug) => {
    console.log("slug in thunk", slug);
    const response = await new Promise((resolve) => {
      socket.emit("getBlogBySlug", slug, (blog) => {
        resolve(blog);
      });
    });
    return response;
  }
);*/
export const getBlogBySlug = createAsyncThunk(
  "blog/getBlogBySlug",
  async (slug, { getState, dispatch }) => {
    const state = getState();
    const existingBlog = selectBlogBySlug(state, slug);
    if (existingBlog) {
      return existingBlog;
    } else {
      try {
        const response = await new Promise((resolve, reject) => {
          socket.emit("getBlogBySlug", slug, (blog) => {
            if (blog) {
              resolve(blog);
            } else {
              reject(new Error("Blog not found"));
            }
          });
        });
        return response;
      } catch (error) {
        // Handle error
        console.error("Error fetching blog:", error);
        throw error;
      }
    }
  }
);
export const getBlogBySlugHTTP = createAsyncThunk('blog/getBlogBySlugHTTP', async (slug) => {
  const response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/blogs/${slug}`);
  return response.data;
});
export const selectBlogBySlug = (state, slug) => {
  if (Array.isArray(state.blog.blogs)) {
    console.log('state blog',state.blog)
    return state.blog.blogs.find((blog) => blog?.slug === slug);
  } else {
    console.log("select blog by slug")
    
    return state.blog.blogs;
  }
};




export default blogSlice.reducer;
