import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { socketConnect } from "./components/socketConnect";

// Lazy load the layout components
const AdminLayout = lazy(() => import("./components/AdminLayout"));
const CustomerLayout = lazy(() => import("./components/CustomerLayout"));
const UserLayout = lazy(() => import("./components/UserLayout"));

function App() {
  /*
  const socket = socketConnect();
  useEffect(() => {
    socket.emit("getSiteTitle", (data) => {
      document.title = data.settings.title;

      const metaDataEvent = new CustomEvent("metaDataReceived", {
        detail: {
          title: data.settings.title,
          description: data.settings.description,
        },
      });

      window.dispatchEvent(metaDataEvent);
    });
  }, []);*/

  return (
    <div className='App'>      
      <Toaster />
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}> 
          <Routes>
            <Route path='/*' element={<CustomerLayout />} />
            <Route path='/admin/*' element={<AdminLayout />} />
            <Route path='/user/*' element={<UserLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
