
import { configureStore,combineReducers } from '@reduxjs/toolkit';
//import counterReducer from '../features/counter/counterSlice';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';

import userReducer from './userSlice';
import usersReducer from './usersSlice';
import profileReducer from './userProfileSlice';
import salesOrderReducer from './salesOrderSlice';
import registerSliceReducer from './registerSlice';
import auctionEventSlice from './auctionEventSlice';
import testimonialSlice from './testimonialSlice';
import blogSlice from './blogSlice';


const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({     
    user:userReducer,
    users:usersReducer,
    register:registerSliceReducer,
    profile:profileReducer,
    orderStore:salesOrderReducer,
    auctionEvent: auctionEventSlice,
    testiMonial: testimonialSlice,
    blog: blogSlice,

});
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});
export const persistor = persistStore(store)
